.notification-settings {
	padding: 20px;
	background: #fff;
	border-radius: 12px;
	height: calc(100vh - 150px);
}

.panel-header {
	display: flex;
	gap: 1rem;
	align-items: center;
	width: 100%;
	border-radius: 8px;
}

.header-row {
	font-weight: bold;
	margin-bottom: 10px;
}

.event-row {
	margin-bottom: 10px;
}

.ant-collapse-item {
	margin-bottom: 16px;
}

:where(.css-dev-only-do-not-override-5d3dga).ant-collapse
	> .ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-5d3dga).ant-collapse
	> .ant-collapse-item:last-child
	> .ant-collapse-header {
	border-radius: 8px 8px 8px 8px;
}
