.role-assign-table {
	margin: 10px 0px !important;
	.ant-table-container {
		min-height: auto !important;
		margin-bottom: 20px !important;
		.ant-table-body {
			overflow-y: hidden;
			max-height: calc(-293px + 100vh);
			margin-bottom: 16px;
		}
	}
	table,
	th,
	td,
	tr {
		border: none !important;
	}
}

.role-assign-modal {
	.ant-modal-content {
		padding: 12px !important;
	}
	.ant-modal-footer {
		margin-top: 0px !important;
	}
}
