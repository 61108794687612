.modal-header {
	justify-content: space-between;
	display: flex;

	&-filter {
		display: flex;
		gap: 3rem;
	}
}

.timelogs-scrollable-content {
	// height: calc(100vh - 300px);
	// overflow-y: auto;
	padding-right: 16px;
	overflow-x: hidden; /* Prevent horizontal scrolling */
	padding-left: 16px; /* Add padding to avoid overflow caused by content */
}

.modal-body {
	padding: 1rem;
	// height: calc(100vh - 300px);
	// overflow-y: auto;
	padding-right: 16px;
	overflow-x: hidden; /* Prevent horizontal scrolling */
	padding-left: 16px; /* Add padding to avoid overflow caused by content */

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}

.overtime-info {
	color: red;
}

.header-toggle {
	margin-top: 10px;
}
.confirm-modal-container {
	.confirm-modal {
		justify-content: center;
		display: flex;
		align-items: center;
		font-size: 46px;
		letter-spacing: -0.13px;
		width: 231px;
		margin: 1.3rem auto 0 auto;
		text-align: center;
		&-text {
			font-size: 18px;
			letter-spacing: -0.13px;
			text-align: center;
			margin-top: 24px;
		}
		&__button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
			margin-top: 3.5rem;
			margin-bottom: 3.2rem;
		}
	}
}
