.requiredAsterisk {
	color: red;
	margin-left: 5px;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 5px;
}

.modal {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4px;

		> h4 {
			text-align: center;
			font-size: 24px;
			margin: 0;
		}

		.close-icon {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
				border-radius: 50%;
			}
		}
	}
	&-buttons {
		margin-top: 15px;
	}
	.col {
		margin-bottom: 20px;
	}
}
