// .notification-modal {
// 	&__title {
// 		margin-bottom: 10px !important;
// 		font-weight: bold;
// 		font-size: 20px;
// 	}

// 	&__list {
// 		max-height: 300px;
// 		overflow-y: auto;
// 		padding-right: 10px;
// 		margin: 10px 0px !important;
// 		margin-bottom: 10px !important;

// 		&--item {
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			padding: 0px 0px 8px 0px;
// 			border-radius: 8px;
// 			margin: 12px 0 !important;
// 		}
// 	}

// 	&--icon__text {
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		height: 200px;
// 		font-size: 16px;
// 		font-weight: 500;
// 		color: #8c8c8c;
// 	}
// }

// .loader-container {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	height: 40vh;
// }

// .footerButtons {
// 	margin-top: 20px;
// 	text-align: right;
// }


.notification-modal {
   &__title {
      margin-bottom: 10px !important;
      font-weight: bold;
      font-size: 20px;
   }

   &__list {
      max-height: 300px;
      overflow-y: auto;
      padding-right: 10px; 
      margin: 10px !important;

      &--item {
         display: flex;
         justify-content: space-between;
         align-items: flex-start; 
         padding: 0px 0px 8px 0px;
         border-radius: 8px;
         margin: 12px 0 !important;

         div {
            &:first-child {
               width: 40%;
               font-weight: bold;
            }

            &:last-child {
               width: 60%; 
               overflow-wrap: break-word; 
               word-break: break-word;
               white-space: normal; 
            }
         }
      }
   }

   &--icon__text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      font-size: 16px;
      font-weight: 500;
      color: #8c8c8c;
   }
}

.loader-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40vh;
}

.footerButtons {
   margin-top: 20px;
   text-align: right;
}

@media (max-width: 768px) {
   .notification-modal {
      width: 100%;
      padding: 10px;

      &__list--item {
         flex-direction: column; 
         div {
            width: 100%; 
            margin-bottom: 5px;
         }
      }
   }
}

