.modal {
	.ant-table-selection-column {
		background-color: white !important;
	}
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}

.ant-select-selection--multiple {
	white-space: nowrap;
	height: 30px;
	overflow: auto;
}
.ant-select-selection--multiple:before,
.ant-select-selection--multiple:after {
	display: inline !important;
}

.view-shift-modal {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}
