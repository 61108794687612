.earningsFields {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	font-size: 16px;
}

.label {
	margin-left: 8px;
	margin-right: 14px;
	width: 250px;
}

.input {
	width: 110px;
	text-align: end;
}

.currency {
	margin-left: 8px;
}

.showMoreLink {
	float: right;
	color: #584495;
	text-decoration: underline;
	cursor: pointer;
}

.earningItems {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-height: 50vh;
	// margin-bottom: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}

.hrSeparator {
	border: none;
	border-top: 1px solid #ccc;
	margin: 20px 0;
}

.inputContainer {
	display: flex;
	gap: 10px;
	margin-right: -28px;
	.ant-btn {
		font-size: none !important;
		min-width: none !important;
		min-height: none !important;
	}
}

.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	padding: 1rem;

	.modal-row {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		gap: 40px;

		.bold-text {
			margin: 10px 10px;
			font-weight: 500;
		}
		//target first child div

		> div {
			font-size: 16px;
		}
	}
	.modal-row:last-child {
		margin-bottom: 0;
	}
}

.modal-buttons {
	margin-top: 20px;
}
