.inline-container {
	display: flex;
	gap: 2.5rem;
	margin-left: 8px;
}
.inline-label {
	display: flex;
	flex-direction: column;
	color: #666666;
	margin-bottom: 30px;
	text-align: left;
	gap: 0.4rem;
	margin-left: 8px;

	.label {
		color: #666666;
		font-size: 12px;
	}

	.label-value {
		color: black;
		font-size: 14px;
	}
}
.global-header {
	width: 100% !important;
	display: flex;
	justify-content: space-between;
}
.download-slip-button {
	background-color: #ffffff !important;
	border-color: #584495 !important;
	color: #584495 !important;
}

.download-button-container {
	margin-top: 5px;
	margin-right: 10px;
}

.usdToggleContainer {
	display: flex;
	justify-content: center;
	margin-top: 8px;
	gap: 13px;
	margin-right: 14px;
	> p {
		margin-top: 1px;
		font-size: 16px;
	}
}
.refresh-button{
	margin-top: 10px;
 margin-left:10px;
}
