// .user-table {
// 	&__action {
// 		display: flex;
// 		gap: 20px;
// 		&--button{
// 			border-radius: 0px ;
// 		}
// 	}

// }/

.user-table {
	margin: 0;
	padding: 0;

	&__action {
		display: flex;
		gap: 20px;

		&--button {
			border-radius: 0;
			padding: 10px 20px;
		}
	}
}
