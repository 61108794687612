.shift {
	&-container {
		background-color: white;
		border-radius: 10px;
	}
}
.shift-header__buttons {
	display: flex;
	gap: 10px;
	justify-content: end;
}
