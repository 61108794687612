.dynamic-table {
  overflow: auto;
  background-color: #fff !important;
  border-radius: 10px;
  height: 70vh !important;
  &__pagination {
    justify-content: center !important;
    .ant-pagination-item {
      border-radius: 50%;
    }
    .ant-pagination-item-active {
      border-color: #000;
      background-color: #000;
      &:hover {
        border: none !important;
        border-width: 0px !important;
      }
      a {
        color: #fff !important;
      }
    }
  }
  .table-edit-icon,
  .table-delete-icon {
    cursor: pointer;
  }

  &__no-action {
    cursor: not-allowed;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    opacity: 40%;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 5px 20px;
  }
}


/* For All the tables */
.ant-table-container {
  min-height: calc(-75px + 70vh) !important;
} 

.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}
.status-approved-by-accounts {
	background-color: #f5f3f7;
	color: #783e9b;
	font-size: 14px;
}
.status-rejected-by-accounts {
	background-color: #fdeeef;
	color: #df081a;
	font-size: 14px;
}
.status-pending-for-approval {
	background-color: #fef7f5;
	color: #ef896e;
	font-size: 14px;
}
.status-paid {
	background-color: #edf8ed;
	color: #019c00;
	font-size: 14px;
}
.statusIcon {
display: flex;
	align-items: center;
	gap: 6px;
}
