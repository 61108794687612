.compensation-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	> p {
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: -0.01em;
		text-align: left;
	}
}
