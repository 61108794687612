.notification-modal {
	&__title {
		margin-bottom: 10px !important;
	}

	&__list {
		margin: 10px 0px !important;
		margin-bottom: 10px !important;
		&--items {
			margin: 10px 0px !important;
			margin-top: 10px !important;
			max-height: 600px;
			overflow-y: auto;
		}
	}
	&--icon {
		display: flex;
		justify-content: center;
		align-items: center;
		&__text {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 30px 0px;
			// font-size: 1.7rem;
		}
	}
}
