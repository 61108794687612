.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}

.status-sent-to-bank {
	background-color: #e6f7ff;
	color: #2f89a6;
	font-size: 14px;
}

.status-payment-under-process {
	background-color: #fff9e6;
	color: #ffa500;
	font-size: 14px;
}

.status-paid {
	background-color: #edf8ed;
	color: #019c00;
	font-size: 14px;
}
.status-placeholder {
	background-color: #fef7f5;
	color: #ef896e;
	font-size: 14px;
}

.statusIcon {
	display: flex;
	align-items: center;
	gap: 6px;
}

.dropdownTrigger {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #a0a0a0;
}

.menuItem {
	display: flex;
	align-items: center;
	.menuIcon {
		margin-right: 8px;
	}
}

.fileLink {
	margin-right: 10px;
	text-decoration: none;
	display: flex;
	align-items: center;

	.fileIcon {
		margin-right: 8px;
		margin-top: 5px;
	}
}
