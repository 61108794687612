.step-container {
	min-width: 1200px;
	// max-width: 1100px;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 8px;
	// position: fixed;
	// z-index: 1000;
	// padding-top: 30px;
	// padding-bottom: 30px;
}

.steps-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// margin-bottom: 16px;
}

.custom-steps {
	width: auto !important;
	flex: 1;
}

.custom-steps .ant-steps-item-title {
	color: #584495 !important;
	text-align: center;
	// margin-top: 5px;
	width: max-content;
	font-size: 15px !important;
}

.custom-steps .ant-steps-item-finish .ant-steps-item-icon,
.custom-steps .ant-steps-item-process .ant-steps-item-icon {
	background-color: #584495 !important;
	border-color: #584495 !important;
}
.custom-steps .ant-steps-item-tail {
	margin-top: 10px !important;
	padding: 0 !important;
	margin-inline-start: 80px !important;
}
.custom-steps .ant-steps-item-icon {
	background-color: white !important;
	border-color: #584495 !important;
	width: 38px !important;
	height: 38px !important;
}

.custom-steps .ant-steps-item-icon .ant-steps-icon {
	color: #584495 !important;
	font-size: 16px !important;
	text-align: center !important;
	top: 9px !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon,
.custom-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
	color: white !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-icon::after,
.custom-steps .ant-steps-item-finish .ant-steps-item-icon::after {
	border-color: #584495 !important;
}

.custom-steps
	.ant-steps-item-finish
	.ant-steps-item-content
	.ant-steps-item-title,
.custom-steps
	.ant-steps-item-process
	.ant-steps-item-content
	.ant-steps-item-title {
	color: #584495 !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-content .completed-text,
.custom-steps .ant-steps-item-finish .ant-steps-item-content .completed-text {
	display: block;
	color: #009049;
	font-size: 12px;
}

.custom-steps .ant-steps-item-finish .ant-steps-item-tail::after,
.custom-steps .ant-steps-item-process .ant-steps-item-tail::after {
	background-color: #584495 !important;
}

.steps-divider {
	border-color: #e7e8eb !important;
	margin-top: 2px !important;
	margin-bottom: 0;
}

.steps-content {
	height: calc(100vh - 206px - 70px);
	// overflow: auto;
}

// .custom-button {
// 	background-color: #584495 !important;
// 	border-color: #584495 !important;
// 	color: #fff;
// }

// .custom-button:hover,
// .custom-button:focus {
// 	background-color: #47367c !important;
// 	border-color: #47367c !important;
// 	color: white !important;
// }
.show-steps {
	width: 3% !important;
	height: auto;
	margin-left: auto !important;
	margin-top: 0;
}

.custom-step-title {
	.ant-steps-item-content {
		margin-top: 6px !important;
	}
}

/* Only these two new style rules were added to create the glow effect */
.custom-steps .ant-steps-item-process .ant-steps-item-icon {
	position: relative;
	border: 2px solid #584495 !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-icon::before {
	content: '';
	position: absolute;
	top: -4px;
	left: -4px;
	right: -4px;
	bottom: -4px;
	border: 5px solid #584495;
	border-radius: 50%;
	opacity: 0.2;
}

.payroll-steps-table {
	margin: 10px 0px !important;
	.ant-table-container {
		min-height: calc(100vh - 400px) !important;
		margin-bottom: 20px !important;
	}
}
.sticky-row {
	position: sticky;
	bottom: 0;
	background: #fff; /* Ensure it's visible over other content */
	z-index: 3; /* Adjust as necessary */
}
