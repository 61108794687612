.ant-collapse-item{
	border: 0px !important;
	// box-shadow: 2px 2px 2px 2px  !important;
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
margin-bottom: 20px;
}
.ant-collapse{
	border: 0px !important;
}
.ant-collapse-item{
	background-color:#fff !important;
}
.ant-collapse-header{
	background-color:#fff !important;
}
