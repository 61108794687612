.horizontal-align {
	display: flex;
	align-items: center;
	gap: 20px;
}

.upload-container .ant-upload,
.upload-container .ant-btn {
	display: inline-block;
	cursor: pointer;
}
