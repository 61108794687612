.modal-header {
	justify-content: space-between;
	display: flex;
	position: sticky;
	top: 0; /* Makes header sticky */
	z-index: 10;
	background: #fff;
	padding: 1rem;

	&-filter {
		display: flex;
		gap: 3rem;
		align-items: center;
	}
}

.modal-body {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 200px); /* Adjust for modal header/footer height */
	overflow: hidden; /* Prevent scrolling on the body */

	.timelogs-scrollable-content {
		flex-grow: 1;
		overflow-y: auto; /* Enable vertical scrolling */
		padding: 16px;
		background: #f9f9f9; /* Optional background color for contrast */
	}
}

.modal-footer {
	position: sticky;
	bottom: 0; /* Makes footer sticky */
	z-index: 10;
	background: #fff;
	padding: 1rem;
}

.header-toggle {
	display: flex !important;
	align-items: center !important;
	gap: 1rem !important;
}

.confirm-modal-container {
	.confirm-modal {
		justify-content: center;
		display: flex;
		align-items: center;
		font-size: 46px;
		letter-spacing: -0.13px;
		width: 231px;
		margin: 1.3rem auto 0 auto;
		text-align: center;
		&-text {
			font-size: 18px;
			letter-spacing: -0.13px;
			text-align: center;
			margin-top: 24px;
		}
		&__button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
			margin-top: 3.5rem;
			margin-bottom: 3.2rem;
		}
	}
}
