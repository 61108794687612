.checkbox {
	margin-left: 10px;
	margin-right: 7px;
	.ant-checkbox-inner,
	.ant-checkbox-input {
		transform: scale(1.5);
	}
	.ant-checkbox-inner::after {
		transform: scale(0.7) rotate(45deg);
		position: relative;
		top: 6%;
	}
}

.earningsFields {
	margin-top: 20px;
	justify-content: space-around;

	.ant-col:nth-child(even) {
		padding-left: 45px !important;
	}
}

.extra-earnings {
	display: flex;
	justify-content: space-between;

	.ant-col:nth-child(even) {
		padding-left: 5px !important;
	}
	.ant-col:nth-child(odd) {
		padding-left: 45px !important;
	}
}
.leave-table {
	margin: 10px 0px !important;
	.ant-table-container {
		min-height: auto !important;
		margin-bottom: 20px !important;
	}
}
