.modal {
	.ant-table-selection-column {
		background-color: white !important;
		max-width: 50px !important;
	}
}

.modal-title {
	font-size: 20px !important;
	font-weight: bolder;
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}
.pdf-btn {
	margin-right: 10px;
}

.addLogsModalRoot {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}

.attendance-log-title {
	font-size: 18px;
	font-weight: 600;
	// border-bottom: 2px solid #f0f0f0;

	.modal-title {
		display: flex;
		align-items: center;
		color: #333;
	}

	.attendance-icon {
		margin-right: 8px;
		font-size: 20px;
	}
}
