.addLeaveRequestModal {
	.ant-upload-drag {
		margin-top: 10px;
	}
	.ant-flex-align-center {
		margin-top: 20px;
	}
}

.addLeaveRequestModalRoot {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}
