.dynamic-table {
	overflow: auto;
	background-color: #fff !important;
	border-radius: 10px;
	height: 70vh !important;

	&__pagination {
		justify-content: center !important;

		.ant-pagination-item {
			border-radius: 50%;
		}

		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;

			&:hover {
				border: none !important;
				border-width: 0px !important;
			}

			a {
				color: #fff !important;
			}
		}
	}

	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}

	&__no-action {
		cursor: not-allowed;
		display: flex;
		align-items: center !important;
		justify-content: center !important;
		opacity: 40%;
	}

	.ant-table-wrapper .ant-table-pagination.ant-pagination {
		margin: 5px 20px;
	}
}

.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}
.status-success {
	background-color: #edf8ed;
	color: #019c00;
	font-size: 14px;
}

.status-rejected {
	background-color: #fff5d9;
	color: #c9a31c;
	font-size: 14px;
}

.status-pending {
	background-color: #fef7f5;
	color: #ef896e;
	font-size: 14px;
}

.statusIcon {
	margin-right: 6px;
	margin-top: 2px;
}
.dropdownTrigger {
	margin-left: 10px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #a0a0a0 !important;
}

.menuItem {
	display: flex;
	align-items: center;
	.menuIcon {
		margin-right: 8px;
	}
}
