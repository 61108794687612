.scrollable-content {
	height: calc(100vh - 430px);
	overflow-y: auto;
	padding-right: 16px;
}

.bank {
	overflow-y: auto;
	height: calc(100vh - 250px);
	&--employeeDetails {
		margin-top: 10px !important;
		width: 99%;
		margin-left: 5px;
	}
}

.form {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 1rem;
	height: calc(100vh - 250px);
	overflow-y: auto;

	&-container {
		&-title {
			font-weight: 500;
		}

		&-card {
			padding: 0rem;
			font-size: 2rem;
		}
	}
}
// .scrollable-content {
// 	height: calc(100vh - 320px);
// 	overflow-y: auto;
// 	padding-right: 16px;
// }
.col {
	margin-bottom: 2rem;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 5px;
	margin-bottom: 10px;
}
.company-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	> p {
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: -0.01em;
		text-align: left;
	}
}
.checkbox-container {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.completedLabel {
	display: flex;
	align-items: center;
	color: #52c41a;

	:global(.anticon) {
		margin-right: 8px;
	}
}

.unVerified {
	color: red;
	display: flex;
	gap: 8px;
	align-items: center;

	:global(.anticon) {
		margin-right: 8px;
	}
}
.confirm-modal-container {
	justify-content: center;
	margin-top: 27rem;

	.confirm-modal {
		justify-content: center;
		display: flex;
		align-items: center;
		font-size: 46px;
		letter-spacing: -0.13px;
		width: 231px;
		margin: 1.3rem auto 0 auto;
		text-align: center;
		&-text {
			font-size: 18px;
			letter-spacing: -0.13px;
			text-align: center;
			margin-top: 24px;
		}
		&__button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
			margin-top: 3.5rem;
			margin-bottom: 3.2rem;
		}
	}
}
