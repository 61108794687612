.notification-table {
	margin: 10px 0px !important;
	.ant-table-container {
		min-height: auto !important;
		margin-bottom: 20px !important;
	}
}

.collapse .ant-collapse-body {
	padding: 12px 16px;
	border-radius: 0px;
}
