.ant-input-number-outlined {
	width: 100%;
}

.ant-form-item {
	padding: 0;
}

.input-column {
	padding: 0px;
}
.day-button {
	font-size: 16px;
	padding: 8px 18px;
	width: 100%;
	height: 100%;
	margin-top: 10px;
	border-radius: 8px;
	margin-bottom: 10px;
}
.selected {
	border-color: #584495;
	color: #584495;
	background: #f5f4f9;
}

#basic_workWeek_help {
	> div {
		margin-block: 16px;
	}
}

.radio-container {
	.ant-form-item {
		margin-bottom: 0px !important;
	}
}
