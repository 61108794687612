.earningsFields {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	font-size: 16px;
}

.label {
	margin-left: 8px;
	margin-right: 14px;
	width: 200px;
}

.input {
	width: 110px;
	text-align: end;
}

.currency {
	margin-left: 8px;
}

.showMoreLink {
	float: right;
	color: #584495;
	text-decoration: underline;
	cursor: pointer;
}

.deductionItems {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-height: 50vh;
	// margin-bottom: 20px;
	overflow-y: auto;
	overflow-x: hidden;
}

.inputContainer {
	display: flex;
	gap: 10px;
	margin-right: -28px;
	.ant-btn {
		font-size: none !important;
		min-width: none !important;
		min-height: none !important;
	}
}
