.total-earning-table {
	// height: calc(100vh - 430px);
	overflow-y: auto;

	thead tr:nth-of-type(2) th {
		background-color: #f9f9fb !important;
	}
	.children-normal-font-weight {
		font-weight: normal !important;
	}
	.border-right-none {
		border-right: none !important;
	}
	.background-color-dark {
		background-color: #f5f4f9 !important;
	}

	.ant-table-cell {
		padding: 14px !important;
	}
}
