.modal-header {
justify-content: space-between;
	display: flex;
	position: sticky;
	top: 0; /* Makes header sticky */
	z-index: 10;
	background: #fff;
	// padding: rem;

	&-filter {
		display: flex;
		gap: 3rem;
		align-items: center;
	}
}
.modal-title {
	font-size: 2.0rem;
  font-weight: bolder; 
}
