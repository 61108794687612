.configuration-menu-item {
	background-color: #fff;
	border: 0px !important;
	.ant-menu-item {
		padding: 10px !important;
		margin: 0 !important;
		width: 100%;
		height: auto !important;
		gap: 8px;
		border-bottom: 2px solid #f3f5fa;
		border-radius: 0%;
		padding-left: 8% !important;

		.ant-menu-title-content {
			font-size: 1.8rem !important;
			color: #000;
			font-weight: 600;
		}
	}
	.ant-menu-item-selected {
		position: relative; // Needed for positioning the pseudo-element
		background-color: #45326c !important;
		margin: 0;
		border-radius: 0;
		width: 100%;
		padding-left: 12px; // Adjust padding-left to ensure content alignment with border

		.ant-menu-title-content {
			color: #fff !important;
		}

		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%; // Start from the middle
		// 	transform: translateY(-50%); // Center the border vertically
		// 	height: 80%; // Adjust this value to set the desired height of the border
		// 	width: 8px;
		// 	background-color: #584495;
		// 	border-top-right-radius: 10px;
		// 	border-bottom-right-radius: 10px;
		// }
	}
	.ant-menu-item-active {
		border-radius: -10%;
	}
}
.ant-layout-sider-children {
	height: 100%;
}
.ant-menu-item {
	gap: 18px !important;
}
