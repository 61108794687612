.calculate-statutory {
	padding-top: 16px;
	> p {
		font-size: 20px;
		// font-weight: 500;
		line-height: 35px;
		letter-spacing: -0.01em;
		text-align: left;
		margin-bottom: 20px;
	}
}
