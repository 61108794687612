.setUpModal {
	width: 314px !important ;
	height: 271px !important;
	position: absolute !important;
	.ant-modal-title {
		> div {
			display: flex;
			justify-content: space-between;
			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
	}
	.ant-modal-footer {
		display: flex;
		justify-content: flex-start;
		gap: 5px;
	}
}
.ant-form-item .ant-form-item-label > label {
	font-weight: bold;
}
