.modal {
	.ant-table-selection-column {
		background-color: white !important;
	}
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}

.addPaymentModalRoot {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}