.confirm-modal {
	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		p {
			font-size: 2.2rem;
			text-align: center;
			margin: 0;
			margin-bottom: 2rem;
			word-break: break-word; 
		}
	}

	.modal-buttons {
		display: flex;
		justify-content: center;
	}
}

