.addLeaveRequestModal {
	.buttons {
		margin-top: 30px;
	}
}
.modalUploadContainer {
	margin-bottom: 20px;
	margin-top: 10px;
	.modalUploadLabel {
		font-size: 1.6rem;
		// font-weight: bold;

		margin-bottom: 40px !important;
	}
}
// .noOfDay-container {
// 	// margin-bottom: 10px;
// 	// margin-top: 10px;
// 	.noOfDay-label {
// 		font-size: 1.6rem;
// 		min-width: 25rem;
// 		font-weight: 600 !important;
// 		color: #000000;
// 		// margin-bottom: 10px;
// 	}
// }
.requiredAsterisk {
	color: red;
	margin-left: 5px;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 5px;
	padding-bottom: 10px;
}

.leave-scrollable-content {
	max-height: calc(100vh - 351px);
	overflow-y: auto;
	padding-right: 16px;
	overflow-x: hidden; /* Prevent horizontal scrolling */
}
