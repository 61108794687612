.employee {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		padding: 1.5rem;
		&-item {
			width: 30rem;
		}
	}

	&-header-right {
		margin-left: auto;
		display: flex;
		align-items: center;
		height: 100%;
	}
	&-header-left {
		margin-right: auto;
		display: flex;
		align-items: center;
		height: 100%;
		font-size: 1.8rem;
		font-weight: 500;
		color: #584495;
	}

	&-year-select {
		width: 120px;
	}
}
.refresh-buttons {
	display: flex;
	gap: 10px;
	justify-content: end;
}
