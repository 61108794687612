.side-drawer-body {
	padding: 0rem 3.636rem;
}

.buttons {
	margin-top: 20px;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 40px;
}
