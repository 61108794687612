.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	padding: 1rem;

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}
.modal-buttons {
	margin-top: 20px;
}
.col {
	margin-bottom: 20px !important;
}

.document-upload {
	// margin-bottom: 15px !important;
	.document-form-label {
		margin-top: 10px !important;
		display: block;
		font-size: 1.6rem;
		min-width: 25rem;
		font-weight: 600 !important;
		color: #000000;
		margin-bottom: 10px !important;
		.required {
			color: red;
		}
	}
}
