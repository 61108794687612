.container {
	max-width: 800px;
	margin: 0 auto;
	padding-inline: 12px;
}

.welcomeTitle {
	text-align: center;
	margin-block: 8px !important;
}

.welcomeDescription {
	display: block;
	text-align: center;
	color: #666666;
	margin-bottom: 12px;
}

.setupProgressContainer {
	background-color: #ffffff;
	border-radius: 8px;

	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.progressHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	padding: 24px 24px 0 24px;
}

.progressTitle {
	color: #584495 !important;
	margin-bottom: 8px !important;
}

.progressDescription {
	color: #666666;
}

.payrollSetupList {
	height: calc(100vh - 300px);
	overflow: scroll;
	padding-inline: 24px;
}

.progressStatus {
	display: flex;
	flex-direction: column;
	text-align: right;

	:global(.ant-progress) {
		width: 100px;
		margin-top: 4px;
	}
}

.stepItem {
	display: flex !important;
	align-items: flex-start !important;

	&.completed {
		.stepIcon,
		.completedLabel {
			color: #52c41a;
		}
	}
}

.stepIcon {
	font-size: 20px;
	margin-right: 16px;
	margin-top: 2px;
	color: #d9d9d9;
}

.uncheckedIcon {
	width: 20px;
	height: 20px;
	border: 2px solid #d9d9d9;
	border-radius: 50%;
}

.stepContent {
	flex: 1;

	p {
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		text-align: left;
		margin-bottom: 2px !important;
		color: #000;
	}
}

.subStep {
	display: flex;
	align-items: center;
	margin-top: 8px;
	color: #666666;

	:global(.anticon) {
		margin-right: 8px;
		font-size: 14px;
	}
}

.viewDetails {
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	display: inline-block;
	// margin-top: 2px;

	:global(.anticon) {
		font-size: 12px;
	}
}

.completedLabel {
	display: flex;
	align-items: center;
	color: #52c41a;

	:global(.anticon) {
		margin-right: 8px;
	}
}

.completedButton {
	color: green;
}
.wavingHand {
  display: inline-block;
  animation: wave 4s cubic-bezier(0.42, 0, 0.58, 1) forwards; 
  transform-origin: 80% 80%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-15deg);
  }
  25% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  85% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg); 
  }
}
