.buttons {
	margin-top: 20px;
}
.monthly-deduction-table {
	width: 50%;
	height: max-content;
	border-radius: 5px;
	border: 1px solid #ccc;
	table-layout: fixed;
	
	
}
.table-container {
	max-height: 250px;
	overflow-y: auto;
	border-radius: 5px;
	border: 1px solid #ccc;
	width: 50%;
	
}

.table-header,
.table-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid #ccc;
	padding:2px;

}

.table-header {
	background-color: #f0f0f0;
	font-weight: bold;
}

.table-cell {
	text-align: center; 
	padding: 4px;
}
.title-monthly-deduction {
	margin-top: 20px;
	margin-bottom: 10px;
	color: '#2F2F2F';
}
