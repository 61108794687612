.scrollable-content {
	height: calc(100vh - 390px);
	overflow-y: auto;
	padding-right: 16px;
}

.buttons {
	margin-top: 20px;
}

.phone-input-container {
	display: flex;
	flex-direction: column;
}

.phone-input-label {
	font-size: 14px;
	color: #000;
	margin-bottom: 4px;
}

.phone-input {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	padding: 4px 11px;
	font-size: 14px;
	height: 32px;
}

.phone-input-error {
	color: #ff4d4f;
	font-size: 12px;
	margin-top: 4px;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 5px;
	margin-bottom: 10px;
}

.dividerStyle {
	margin: 1.5rem 0rem !important;

	border-color: #b6b4bd !important;
}
