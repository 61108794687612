.tooltip-content {
	width: 300px; // Adjust the width as needed
	padding: 10px;

	color: #000;

	.tooltip-header {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding-bottom: 8px;
		border-bottom: 1px solid #d9d9d9;
		margin-bottom: 8px;
	}

	.tooltip-row {
		display: flex;
		justify-content: space-between;
		padding: 4px 0;
		border-bottom: 1px solid #d9d9d9;

		&:last-child {
			border-bottom: none;
		}

		.tooltip-cell {
			text-align: left;

			&:last-child {
				text-align: right;
			}
		}
	}
}
