.modal {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 0.5rem;

		> h4 {
			text-align: center;
			margin: 0;
		}

		.close-icon {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
				border-radius: 50%;
			}
		}
	}

	&-body {
		padding: 0 2.5rem;
		.form-container-card {
			.row {
				width: 100%;
				margin-bottom: 24px;

				.col {
					margin-bottom: 0 !important;

					.input-container {
						position: relative;
						width: 100%;
						.input-title {
							font-size: 16px;
					//		font-weight: 500;

							&::after {
								content: '*';
								margin-left: 8px;
								font-size: 14px;
								color: red;
							}
						}
						.ant-input-number-wrapper,
						.ant-input-number {
							width: 100%;
						}

						.error-message {
							position: absolute;
							top: 100%;
							left: 0;
							font-size: 12px;
							color: #ff4d4f;
							margin-top: 4px;
							line-height: 1;
						}
					}

					.icon-container {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;

						.icon {
							font-size: 24px;
						}
					}
				}
			}
		}
	}

	&-buttons {
		margin-top: 15px;
	}
	.col {
		margin-bottom: 20px;
	}

	.checkbox-wrapper {
		> div {
			margin: 38px;
		}
	}
}
