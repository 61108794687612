.modal {
	.ant-table-selection-column {
		background-color: white !important;
		max-width: 50px !important;
	}
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}
.monthly-logs-root {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}
.monthly-modal {
	.ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
		background: none;
	}
}
