.configuration-layout {
	height: calc(100vh - 390px);
	&__wrapper {
		display: flex;
		height: calc(100vh - 140px);
	}
	&__body {
		overflow: auto;
		width: 100%;
		background: #fff;
		padding: 0.1rem 1rem;
		border-radius: 0 10px 10px 0;
	}
}
