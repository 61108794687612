.checkbox-container {
	margin: 20px;
	.ant-checkbox-inner,
	.ant-checkbox-input {
		transform: scale(1.5);
	}
	.ant-checkbox-inner::after {
		transform: scale(0.7) rotate(45deg);
		position: relative;
		top: 6%;
	}

  &-label {
		margin-left: 8px;
    font-size: larger;
  }
}
