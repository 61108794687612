.modal {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 0.5rem;

		> h4 {
			text-align: center;
			margin: 0;
		}

		.close-icon {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
				border-radius: 50%;
			}
		}
	}
}
.tooltip-content {
	width: 600px;
	padding: 12px;
	color: #000;

	.tooltip-header {
		display: flex;
		justify-content: space-between;
		font-weight: bold;
		padding-bottom: 8px;
		border-bottom: 1px solid #d9d9d9;
		margin-bottom: 8px;
	}

	.tooltip-row {
		display: flex;
		justify-content: space-between;
		padding: 4px 0;
		border-bottom: 1px solid #d9d9d9;

		&:last-child {
			border-bottom: none;
		}

		.tooltip-cell {
			text-align: left;

			&:last-child {
				text-align: right;
			}
		}
	}
}

.column-tooltip {
	display: flex;
	flex-direction: column;

	.grey-color {
		color: gray;
	}
	.bold-content {
		font-weight: 400;
	}
}

.row {
	display: flex;
	justify-content: space-between;
	gap: 2px;
}
