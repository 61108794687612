.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 20px, 10px, 12px;
		border-radius: 8px;
	}
}

.status-active {
	background-color: #e5f8e8;
	color: #28a745;
	font-size: 14px;
}
.status-deceased {
	background-color: #f0f0f0;
	color: #6c757d;
	font-size: 14px;
}
.status-dismissed {
	background-color: #ffe5e5;
	color: #dc3545;
	font-size: 14px;
}
.status-redundant {
	background-color: #ffece5;
	color: #ff5722;
	font-size: 14px;
}
.status-retired {
	background-color: #e5f4ff;
	color: #0077f6;
	font-size: 14px;
}
.status-reassigned {
	background-color: #f3e5ff;
	color: #8f3ebd;
	font-size: 14px;
}
.status-suspended {
	background-color: #fff9e5;
	color: #e3ab00;
	font-size: 14px;
}

.statusIcon {
	display: flex;
	align-items: center;
	gap: 6px;
	//margin-bottom: 100px !important;
}
