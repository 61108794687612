.container {
	font-size: 14px;
}

.infoSection {
	margin-top: 24px;
	margin-bottom: 24px;
}

.infoItem {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
	:first-child {
		font-weight: bold;
		margin: 0;
		font-size: 14px;
	}
}

.footerButtons {
	margin-top: 20px;
	text-align: right;
}
