.modal-header {
	justify-content: space-between;
	display: flex;
	position: sticky;
	top: 0; /* Makes header sticky */
	z-index: 10;
	background: #fff;
	// padding: rem;

	&-filter {
		display: flex;
		gap: 3rem;
		align-items: center;
	}
}
.modal-title {
	font-size: 2rem;
	font-weight: bolder;
}

.shift-scrollable-content {
	max-height: calc(100vh - 300px);
	overflow-y: auto;
	padding-right: 16px;
	overflow-x: hidden; /* Prevent horizontal scrolling */
	padding-left: 16px; /* Add padding to avoid overflow caused by content */
}

.modal-body {
	padding: 1rem;

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}

.modal-buttons {
	margin-top: 20px;
}
.col {
	margin-bottom: 25px !important;
}
.confirm-modal-text {
	font-size: 1.5rem;
}
.confirm-modal-buttons {
	margin-top: 30px;
	margin-bottom: 10px;
	justify-content: end;
	display: flex;
}
