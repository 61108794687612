.sidebar {
	min-width: 290px !important;
	border-radius: 10px 0 0 10px;
	// border-right: 1px solid #f3f5fa !important;
	border: none !important;
	padding-top: 1.5rem !important;
	padding: 8px;
	.sidebar__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 1px solid #d7d9dd;
		border-radius: 10px;

		.ant-menu-item:last-child {
			border-bottom: 0;
		}
		&_menuItems {
			padding: 3px;
		}
	}
	.getStarted {
		padding: 16px;
		background-color: #f5f4f9;
		margin-bottom: 12px;
		cursor: pointer;
		border-radius: 10px;
		&__title {
			font-size: 1.6rem;
			color: #584495;
			font-weight: 600;
			margin-bottom: 6px;
		}

		&__progress {
			font-size: 1.4rem;
			color: #8a8a8a;
			margin-bottom: 8px;
		}

		&__bar {
			width: 100%;
			height: 4px;
			background-color: #e0e0e0;
			border-radius: 2px;

			&__filled {
				width: 0;
				height: 100%;
				background-color: #584495;
				border-radius: 2px;
			}
		}
	}
}

.menu_item {
	display: flex;
	gap: 1.5rem;
	justify-content: start;
	align-items: self-start;

	.stepIcon,
	.completedLabel {
		font-size: 1.6rem;
		color: #52c41a;
	}
}

.completedLabel {
	display: flex;
	align-items: center;
	color: #52c41a;

	:global(.anticon) {
		margin-right: 8px;
	}
}
