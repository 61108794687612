
.earningsContainer {
  padding: 20px;
  font-family: 'Inter', sans-serif;
}

.earningsItem {
  display: flex;
  align-items: center; 
  padding: 10px 10px;
  border-radius: 8px;
  transition: box-shadow 0.2s ease;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: black;
  text-align: left;
  margin-right: 12px; 
}

.amount {
  font-size: 16px;
  color: #584495;
  font-weight: 600;
  text-align: right;
	 margin-left: 12px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
