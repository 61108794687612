.dynamic-table {
	background-color: #fff !important;
	border-radius: 10px;

	&__pagination {
		justify-content: center !important;

		.ant-pagination-item {
			border-radius: 50%;
		}

		.ant-pagination-item-active {
			border-color: #000;
			background-color: #000;

			&:hover {
				border: none !important;
				border-width: 0px !important;
			}

			a {
				color: #fff !important;
			}
		}
	}

	.table-edit-icon,
	.table-delete-icon {
		cursor: pointer;
	}

	&__no-action {
		cursor: not-allowed;
		display: flex;
		align-items: center !important;
		justify-content: center !important;
		opacity: 40%;
	}

	.ant-table-wrapper .ant-table-pagination.ant-pagination {
		margin: 5px 20px;
	}
}

/* For All the tables */
