.login {
	height: 100vh;
	&__wrapper {
		height: 100%;
	}
	&__details {
		max-width: 57.4rem;
		// padding-top: 12.8rem;
		margin: 0 auto;
		&--logo {
			height: 6.4rem;
			width: 35.1rem;
			margin-bottom: 3.7rem;
			& > img {
				height: 100%;
				width: 100%;
			}
		}
	}
	&__layout {
		background-color: #F9F9F9;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		&--image {
			height: 79.5rem;
			width: 62.7rem;
		}
	}
}
