.form {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 1rem;
	height: calc(100vh - 200px);
	overflow-y: auto;
	//width: 100%;
	//align-items: center;

	&-container {
		&-card {
			font-size: 1.6rem;
			width: 100%;
			max-width: 400px;
		}

		&-title {
			font-weight: 500;
			color: #584495;
			text-align: center;
			margin-bottom: 12px;
		}

			&-warning {
				color: red;
				font-size: 14px;
			}
		}
	

	.selectPayPoint {
		margin-left: 10px;
	}

	.bold-label {
		font-weight: bold;
	}
}

.col {
	margin-bottom: 2rem;
}

.collapse {
	display: flex;
	justify-content: center;
	align-items: center;

	&-menu {
		width: 50%;
		padding: 20px;
		align-items: center !important;
	}

	&-title {
		font-size: 20px;
		margin-bottom: 10px;
	}

	&-required {
		color: red;
	}

	&-item {
		margin-top: 10px;
		margin-bottom: 10px;
		align-items: center;
	}

	&-header {
		margin-top: 10px;
		margin-bottom: 10px;
		align-items: center;
	}
}
