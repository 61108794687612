.checkbox {
	margin-left: 10px;
	margin-right: 7px;
	.ant-checkbox-inner,
	.ant-checkbox-input {
		transform: scale(1.5);
	}
	.ant-checkbox-inner::after {
		transform: scale(0.7) rotate(45deg);
		position: relative;
		top: 6%;
	}
}
.deductionsFields {
	margin-top: 20px;
	justify-content: space-around;

	.ant-col:nth-child(even) {
		padding-left: 220px !important;
	}
}
