.payrollSetting-menu-item {
	background-color: #fff;
	border: 0px !important;
	.ant-menu-item {
		padding: 10px !important;
		margin: 0 !important;
		width: 100% !important;
		height: auto !important;
		border-bottom: 2px solid #f3f5fa;
		padding-left: 8% !important;

		&:last-child {
			border-bottom: 0;
		}

		.ant-menu-title-content {
			font-size: 1.8rem !important;
			color: #000;
			font-weight: 600;
		}

		&:hover {
			background: #fff !important;
		}
	}
	.ant-menu-item-selected {
		background: #f2f2f2 !important;
		border-radius: 0;
		width: 100%;
	}
}

.ant-layout-sider-children {
	height: 100%;
}
.ant-menu-item {
	gap: 18px !important;
}

.Icon {
	.anticon {
		svg {
			width: 2rem !important;
			height: 2rem !important;
			margin-top: 10px;
		}
	}

	.uncheckIcon {
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		margin-top: 10px;
		border: 2px solid #d9d9d9;
	}
}
