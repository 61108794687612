.employee {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
		padding: 1.5rem;
		&-item {
			width: 30rem;
		}
	}
}
.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	padding: 1rem;

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}
.modal-buttons {
	margin-top: 20px;
}
.col {
	margin-bottom: 20px !important;
}

.document-upload {
	// margin-bottom: 15px !important;
	min-height: '200px !important';
	.document-form-label {
		margin-top: 10px !important;
		display: block;
		font-size: 1.6rem;
		min-width: 25rem;
		font-weight: 600 !important;
		color: #000000;
		margin-bottom: 10px !important;
		.required {
			color: red;
		}
	}
}
.csv-button {
	border: 1px solid #ccc;
	padding: 8px 10px;
	background-color: #fff;
	cursor: pointer;
	border-radius: 5px;
}

.confirm-delete-model {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	&__title {
		font-size: 16px;
		letter-spacing: -0.13px;
		margin: 1rem;
		text-align: left;
	}
}
.confirm-delete-model__button {
	display: flex;
	align-items: end;
	justify-content: end;
	gap: 16px;
	&--btn {
		border: none;
		font-size: 1rem;
		border-radius: 5000px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: flex-end;
		justify-content: end;
		background: #e74032;
		font-size: 1rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		border: 1px solid #e74032;
		cursor: pointer;
		width: 13rem;
		height: 50px;
		&:hover {
			background: #e74032;
		}
	}
}
.refresh-buttons {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.template-link {
	text-decoration: underline;
	color: #584495;
	margin-right: 5px;
	cursor: pointer;
	display: flex;
	justify-content: center;
}
