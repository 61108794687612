.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 5px;
}
