.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	padding: 1rem;

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}
.modal-buttons {
	margin-top: 20px;
}
.col {
	margin-bottom: 10px !important;
}
.form-group {
	// margin-bottom: 15px !important;
	.form-label {
		display: block;
		font-size: 1.6rem;
		min-width: 25rem;
	//	font-weight: 600 !important;
		color: #000000;
		margin-bottom: 10px !important;
		margin-top: 20px;
		.required {
			color: red;
		}
	}
}

.currency-group {
	display: flex;
	align-items: center;
	gap: 2rem;
	height: 53px;
	&_checkbox {
		width: 15rem;
	}
}

.error-text {
	color: red;
	font-size: 1.6rem;
	font-weight: 500;
}
