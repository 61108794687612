.table-action-header {
	margin-bottom: 15px;
	margin-top: 15px;
	padding: 0px 0px;

	&__wrapper {
		display: flex;
	}
	&__title {
		display: flex;
		align-items: center;

		h3 {
			font-size: 2.4rem;
			font-weight: 600;
		}
		&__activeConnection {
			display: flex;
			align-items: center;
			font-weight: 600;
			color: #0b78c2;
			&--pipline {
				border-left: 2px solid grey;
				margin: 0px 10px;
				height: 30px;
			}
		}
	}
	&__actions {
		display: flex;
		justify-content: flex-end;
		gap: 15px;
		justify-content: center;
		align-items: center;
	}
	&-line {
		margin-top: 1%;
	}
}
