.taxation-details {
	.card {
		background: #fff;
		border-radius: 12px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
		padding: 8px;
		margin-top: 6px;
		margin-bottom: 6px;
		border-radius: 8px;

		.card-title {
			font-size: 18px;
			font-weight: 600;
			color: #584495;
		}

		.card-text {
			color: black;
			font-size: 15px;
			line-height: 1;
		}

		p {
			margin: 12px 0;
			font-size: 18px;
			line-height: 1.6;

			b {
				font-weight: 600;
				margin-right: 8px;
				color: black;
			}
		}
		.ant-card-head {
			background-color: #f0eef8;
			border-bottom: 1px solid #d3cce3;
		}

		.ant-card-body {
			padding: 16px 24px;
		}

		.ant-card-head-title {
			font-size: 16px;
			font-weight: 600;
			color: #584495;
		}
	}
}
