// index.module.scss
.modal {
	width: 500px !important;
	padding: 1rem;
}

.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	.header-content {
		display: flex;
		align-items: center;
		gap: 10px;

		h4 {
			margin: 0;
		}

		.show-all {
			color: #584495;
			font-size: 14px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.dismiss-all {
		color: rgb(230, 97, 97);
		font-size: 14px;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	&--notification-list {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		gap: 16px;
		max-height: 60vh;
		overflow-y: scroll;

		&__item {
			display: flex;
			align-items: flex-start;
			gap: 12px;
			padding: 8px;
			border-radius: 4px;
			transition: background-color 0.3s;

			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
}

.status-dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 8px;

	&.PUSH {
		background-color: #52c41a;
	}

	&.APPROVAL {
		background-color: #584495;
	}
	&.delete {
		background-color: #fe1c1c;
	}
}

.content {
	flex: 1;

	.content-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 8px;

		.title {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.85);
			font-weight: 500;
		}

		.more-icon {
			cursor: pointer;
			padding: 4px;
			border-radius: 50%;

			&:hover {
				background-color: #f0f0f0;
			}
		}
	}

	.meta {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.45);
		margin-top: 4px;
	}

	.action {
		margin-top: 8px;
	}
}

.action-button {
	display: inline-block;
	padding: 4px 15px;
	border-radius: 2px;
	font-size: 14px;
	cursor: pointer;
	color: #584495;
	background: transparent;
	border: 1px solid #584495;
	transition: all 0.3s;

	&:hover {
		background: #584495;
		color: white;
	}
}

// For Try Again button, you might want to add a modifier class
.action-button.try-again {
	background: #584495;
	color: white;

	&:hover {
		opacity: 0.9;
	}
}

.notification_message {
	span:nth-child(2n) {
		color: #584495;
	}
}

.emptyContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	// padding: 48px 24px;
	margin-top: 12px;

	:global(.ant-empty) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.icon {
	font-size: 48px;
	color: #bfbfbf;
	margin-bottom: 8px;
}

.description {
	text-align: center;
	max-width: 400px;
}

.title {
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 8px;
	font-weight: 500;
}

.text {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5;
	margin: 0;
}
