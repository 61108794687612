.modal {
	.ant-table-selection-column {
		background-color: white !important;
	}
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}

.addEmployeePayGroupModal {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}

.table-pay-group {
	.ant-table-middle {
		max-height: 120px !important;
	}
}
