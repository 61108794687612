.modal-header {
	justify-content: space-between;
	display: flex;
}

.modal-body {
	padding: 1rem;
	margin-bottom: 0;
	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}
.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}
.status-success {
	background-color: #edf8ed;
	color: #019c00;
	font-size: 14px;
}

.status-rejected {
	background-color: #fff5d9;
	color: #c9a31c;
	font-size: 14px;
}

.status-pending {
	background-color: #fef7f5;
	color: #ef896e;
	font-size: 14px;
}

.statusIcon {
	margin-right: 6px;
	margin-top: 2px;
}

.dropdownTrigger {
	margin-left: 10px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #a0a0a0 !important;
}

.menuItem {
	display: flex;
	align-items: center;
	.menuIcon {
		margin-right: 8px;
	}
}

.statusContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.statusDisplay {
	display: flex;
	align-items: center;
}

.statusAndEdit {
	display: flex;
	align-items: center;
}

.statusTag {
	display: flex;
	align-items: center;
}

.dropdownTrigger {
	margin-left: 8px;
}
.saveButton {
	height: 30px;
}

.company-account-details {
	display: flex;
	margin-bottom: 10px;
	gap: 30px;
	align-items: center;
	font-size: 16px;
}
.details {
	font-weight: bold;
}
.details-name {
	font-weight: normal;
}
.status {
	display: flex;
	align-items: center;
	gap: 6px;
}
