.modal {
	.ant-table-selection-column {
		background-color: white !important;
	}
}

.total-row {
	.ant-table-cell {
		background-color: #dedede !important;
	}
}
.pdf-btn {
	margin-right: 10px;
}
