.directDepositForm {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 1rem;
	overflow-y: auto;

	&-container {
		&-title {
			// font-weight: 400;
			font-size: 15px bold;
			text-align: center;
			margin-bottom: 10px;
		}

		&-card {
			font-size: 1rem;
		}
	}
}

.input {
	margin-bottom: 1rem;
}

.required {
	color: red;
}

.heading {
	font-size: 15px;
}

.buttons {
	margin-top: 20px !important;
}

.verfiyButton {
	margin-top: 3.2rem;
}

.completedLabel {
	display: flex;
	align-items: center;
	color: #52c41a;

	:global(.anticon) {
		margin-right: 8px;
	}
}
