.preview-container {
	&__top-close {
		&:hover {
			cursor: pointer;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2.3rem;

		&-title {
			font-weight: 600;
			font-size: 2.4rem;
		}
	}

	&__content {
		background-color: #ffffff;
		border-radius: 0.8rem;

		&--new-employee {
			height: 100vh !important;

			&-header {
				// display: flex;
				margin-bottom: 1rem;
				margin-top: 1rem;

				&-tabs {
					background-color: white;
				}

				&-first {
					font-size: 18px;

					&--id {
						font-size: 1.8rem;
						font-weight: 500;
					}
				}

				&-second {
					display: flex;
					gap: 3rem;
					font-size: 18px;
					align-items: center;
					justify-content: flex-end;
					margin-top: 1rem;

					//  align-items: end;
					&-earnings {
						font-size: 16px;
						background-color: #edf8ed;
						border: none;

						&:hover {
							cursor: pointer;
							background-color: #edf8ed !important;
							border: none;
							color: black !important;
						}
					}

					&-deductions {
						font-size: 16px;

						background-color: #fdeeef;
						border: none;

						&:hover {
							cursor: pointer;
							background-color: #fdeeef !important;
							border: none;
							color: black !important;
						}
					}
				}
			}
		}

		&--footer {
			padding: 3.8rem;

			&-textarea {
				margin-top: 1.6rem !important;
				margin-bottom: 3.6rem !important;
				height: 8rem;
			}

			&-upload {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: start;

				& p {
					font-weight: 500;
				}
			}

			&-notes {
				& label {
					font-weight: 500;
				}
			}

			// &-buttons {
			// 	display: flex;
			// 	gap: 2rem;

			// 	&-button {
			// 		width: 16rem;
			// 		height: 4.2rem;
			// 		border-radius: 4rem;
			// 		border: 0;
			// 		background-color: #000;
			// 		color: #fff;
			// 		&:hover {
			// 			cursor: pointer;
			// 		}
			// 	}
			// 	&-cancel {
			// 		width: 12rem;
			// 		height: 4.2rem;
			// 		border-radius: 4rem;
			// 		border: 0rem;
			// 		background-color: #e3e4e7;
			// 		color: #a2a7ad;
			// 		&:hover {
			// 			cursor: pointer;
			// 		}
			// 	}
			// }
		}
	}
}

.divider {
	display: inline-block;
	width: 1px;
	height: 20px;
	background-color: #d9d9d9;
	margin: 0 10px;
	vertical-align: middle;
}
