.modal-body {
	color: #27272e !important;
	font-weight: 500;
	margin: 1rem 0rem 5.5rem 0rem !important;

	.fields {
		padding: 1rem !important;
	}
	.ant-select {
		width: 100% !important;
	}

}


.document_modal_footer {
	margin-top: 8rem !important;
	.save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #286fd1;
		font-size: 1.8rem;
		color: #fff;
		font-weight: 600;
		border: none;
		cursor: pointer;
		width: 10.5rem;
		min-height: 4.5rem;

		&:hover {
			color: #fff !important;
			background: #286fd1 !important;
		}
	}

	.cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
		border: 2px solid #e3e4e7;
		box-shadow: none;
		font-size: 1.8rem;
		color: #a2a7ad;
		font-weight: 600;
		cursor: pointer;
		width: 10.5rem;
		min-height: 4.5rem;

		&:hover {
			border-color: #e3e4e7 !important;
			background: #fff !important;
			color: #a2a7ad !important;
		}
	}
}

hr {
	height: 1px;
	background-color: #ccc;
	border: none;
}
.ant-modal-close {
	margin-top: 2rem;
	margin-right: 2.1rem;
}

// .userDetailsTitle {
// 	font-size: 2rem !important;
// }

.document-form-label {
	margin-top: 10px !important;
	display: block;
	font-size: 1.6rem;
    min-width: 25rem;
    font-weight: 600 !important;
    color: #000000;
    margin-bottom: 10px !important;
}

.__popup {
	.ant-modal-content {
		padding: 0px !important;
	}
}
