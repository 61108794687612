.modal {
	background-color: #fff;
	border-radius: 8px;
	padding: 1rem;

	&-header {
		justify-content: space-between;
		display: flex;
	}

	&-body {
		margin-bottom: 0rem;
		.bank-option {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: white;
			border-radius: 8px;
			padding: 0rem 1rem;
			margin-bottom: 1rem;
			border: 1px solid #d3d3d3;
			//box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
			cursor: pointer;
			transition: background-color 0.3s ease;

			&:hover {
				background-color: #f0f0f5;
			}

			&.selected {
				background-color: #fff;
				border: 2px solid #584495;
			}

			.bank-details {
				display: flex;
				align-items: center;
			}

			.bank-logo {
				width: 80px;
				height: 50px;
				margin-right: 1rem;
			}

			.bank-name {
				font-size: 1.2rem;
				font-weight: 500;
			}

			.check-balance-button {
				background-color: #fff;
				border: 1px solid #d3d3d3;
				color: #595959;
				//	border-radius: 20px;
				cursor: pointer;
				padding: 0 4px;

				&:hover {
					background-color: #f0f0f5;
					color: #000;
				}
			}
		}
	}
}
