.history-table {
	margin: 10px 0px !important;
	height: calc(100vh - 300px);
		overflow-y: auto;
		padding-right: 16px;
		overflow-x: hidden; /* Prevent horizontal scrolling */
		padding-left: 16px;
	.ant-table-container {
		min-height: auto !important;
		margin-bottom: 20px !important;
	}

}
.history-modal-root {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}
}
