// .container {
// 	padding: 14px;
// }

.P18-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 14px 0 14px;
	margin-bottom: 20px;
	> p {
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: -0.01em;
		text-align: left;
	}
}

.divider {
	height: 35px;
	/* Adjust the height as needed */
	width: 1px;
	background-color: #d3d3d3;
	/* Color of the line */
	margin: 0 10px;
	/* Space around the line */
}
