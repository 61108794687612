.statutory-component {
	padding-top: 24px;
}

.modal-header {
	justify-content: space-between;
	display: flex;

	&-filter {
		display: flex;
		gap: 3rem;
	}
}	