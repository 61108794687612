.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 6px;
	.buttons {
		display: flex;
		gap: 12px;
		button {
			min-width: 12px;
			min-height: 4rem;
			color: white;
			align-items: center;
		}
		.secondary-btn {
			display: flex;
			font-size: 3rem;
			min-width: 12px;
			min-height: 4rem;
			color: grey;
			align-items: end;
		}
	}
}

.pop-over-details {
	min-height: 50px;
	&--divider {
		margin: 9px 0px !important;
	}
	&--content {
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
	}
}
