.settings-layout {
	height: calc(100vh - 370px);

	&__wrapper {
		display: flex;
		height: calc(100vh - 74px);
	}
	&__body {
		overflow: auto;
		width: 100%;
		background: #f3f5f7;
		padding: 0.1rem 3rem;
	}
}
