.form {
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 1rem;
	height: calc(100vh - 150px);
	overflow-y: auto;

	&-container {
		&-title {
			font-weight: 500;
		}
		&-card {
			padding: 2rem;
			font-size: 2rem;
		}
	}
}

.col {
	margin-bottom: 2rem;
}

.modal-buttons {
	margin-top: 15px;
}
