.attendance-report {
	padding: 20px;
	background-color: #f3f5f7;
	font-size: 8px !important;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100vh;
	// justify-content: center;
	&__header {
		display: flex;
		justify-content: space-between !important;
		align-items: center;
		margin-bottom: 20px;
		&__title {
			font-size: 2.4rem;
			font-weight: 600;
		}
		&__actions {
			cursor: pointer;
			display: flex;
			align-items: center;
			&__button {
				margin-right: 10px;
			}
		}
	}
	&__table {
		display: flex;
		border-radius: 10px;
		background-color: white;
		padding: 20px;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
		height: 100%;
	}
}
