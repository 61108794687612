.dynamic-permission-table {
	padding-left: 2rem;
	padding-right: 2rem;
}
.side-drawer-form {
	padding: 0px 32px 32px 32px;
	&__buttons {
		display: flex;
		align-items: center;
		gap: 2rem;

		margin-top: 3rem;
		&--btn {
			border: none;
			font-size: 1.6rem;
			padding: 1.3rem 2.8rem;
			border-radius: 6px;
			display: flex;
			padding: 1.3rem 2.8rem;
			max-height: 41.8px;
			align-items: center;
			cursor: pointer;
			font-weight: 600 !important;
		}
		&--save {
			align-items: center;
			justify-content: center;
			background: #584495;
			font-size: 1.6rem;
			color: #fff;
			font-weight: 600;
			border-radius: 6px !important;
			border: 1px solid #584495; 
			padding: 2rem 2.8rem;
			cursor: pointer;
			width: 13rem;
			max-height: 41.8px ;
			&:hover {
				background: #584495 !important;
				color: white !important;
			}
		}
		&--cancel {
			display: flex;
			padding: 2rem 2.8rem;
			align-items: center;
			justify-content: center;
			background: #fff;
			border: 1px solid #000;
			box-shadow: none;
			font-size: 1.6rem;
			max-height: 41.8px ;
			color: #000;
			font-weight: 600;
			border-radius: 6px !important;
			cursor: pointer;
			width: 13rem;
			// min-height: 5.2rem;
			&:hover {
				background: #fff !important;
				color: #27272E !important;
				border-color: #000 !important;
			}
		}
	}
}
