.employeeActivity {
	margin-left: 3rem;
	max-height: calc(100vh - 80px);
	overflow-y: auto;
	margin-right: 3rem;
	// Container for the approval message
	&--container {
		padding: 10px 15px;
		border-radius: 5px;
		max-width: auto;

		// Status section
		&__status {
			display: flex;
			align-items: start;
			gap: 10px;
			margin-bottom: 5px;
			margin-top: 5px;

			// Approved icon
			&--icon {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
				margin-top: 6px;
			}
			&--details {
				color: #000;
				font-size: 14px;

				// Name and timestamp spacing
				&__title {
					font-weight: bold;
					color: #333333;
				}

				.timestamp {
					margin-left: 10px;
					color: #888888;
					font-size: 12px;
				}
			}
		}
	}

	&--show-more {
		display: flex;
		justify-content: right;
		margin-top: 10px;
		color:#5a4aab;
		margin-right: 10px;
		margin-bottom: 20px;
		font-size: 1.6rem;
		cursor: pointer;
	}

	// Name and timestamp section

	// Approval message
	.message {
		margin-top: 10px;
		color: #5a4aab; // Purple color
		font-size: 14px;
	}
}
