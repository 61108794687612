.headerBox {
	border-bottom: 1px solid #d9d9d9;
}

.headerContent {
	display: flex;
	justify-content:space-between;
}

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 246px;
	gap: 22px;
	height: 94px;
	padding: 30px;
	min-width: 246px;
}

.titleText {
	font-size: 24px;
}

.earningsAmount {
	color: #009c1c;
	font-size: 22px;
}
.titleEarnings {
	font-size: 14px;
	min-width: 97px;
	white-space: nowrap;
}

.separation {
	border-left: 1px solid #bdb8b8;
	height: 44px;
}

.yearMonthContent {
	margin-bottom: 10px;
}
// .modalButtonContent {
// 	padding: 15px;
// }
// .modalButton {
// 	border-top: 1px solid #d9d9d9;
// 	padding: 20px;
// }

// .modalButton button {
// 	margin-top: 20px;
// }
.buttons{
	margin-top: 20px;
}


.earningsFields {
	padding: 20px;
}

.earningContainer{
	width:1200px;
  margin:auto;	
	margin-top:10px;
}
