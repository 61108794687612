// .container {
// 	padding: 14px;
// }

.payroll-summary-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 14px 0 14px;
	margin-bottom: 20px;
	> p {
		font-size: 20px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: -0.01em;
		text-align: left;
	}
}
