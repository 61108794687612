
.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}
.status-approved-by-accounts {
	background-color: #f5f3f7;
	color: #783e9b;
	font-size: 14px;
}
.status-rejected-by-accounts {
	background-color: #fdeeef;
	color: #df081a;
	font-size: 14px;
}
.status-pending-for-approval {
	background-color: #fef7f5;
	color: #ef896e;
	font-size: 14px;
}
.statusIcon {
display: flex;
	align-items: center;
	gap: 6px;
}
