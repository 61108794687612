
.header{
  margin-top:20px!important;
  margin-bottom: 31px;

  p{
  font-size:22px !important;
  font-weight: 600 !important;

  }
  button{
width: 100px;
   height: 38px ;
  }
}
