.scrollable-content {
	overflow-y: scroll;
	max-height: calc(100vh - 170px);
	padding-right: 16px;
	padding-left: 16px;
	background-color: #f9f9f9;
}

.card {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	padding: 8px;
	margin-top: 6px;
	margin-bottom: 6px;
	border-radius: 8px;
	min-height: 48px !important;

	.ant-card-head-title {
		font-size: 16px;
		font-weight: 600;
		color: #584495;
	}

	p {
		margin: 12px 0;
		font-size: 18px;
		line-height: 1.6;

		b {
			font-weight: 600;
			margin-right: 8px;
			color: black;
		}
	}
}

.card-title {
	color: #584495 !important;
	font-weight: bold;
	font-size: 18px !important;
}

.card-text {
	color: black;
	font-size: 15px;
	line-height: 1;
}

.ant-card-head {
	background-color: #f0eef8;
	border-bottom: 1px solid #d3cce3;
}

.ant-card-body {
	padding: 16px 24px;
}
