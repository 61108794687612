.payrollOverview {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		padding: 1.5rem;

		&-item {
			width: 20rem;
		}
		
	}
}