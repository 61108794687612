.global-layout {
	height: 100vh;

	&__body {
		display: flex;
		height: calc(100vh - 74px);
		&--body {
			overflow: auto;
			width: calc(100vw - 200px);
			background: #f3f5f7;
			padding: 0rem 3rem 1rem 3rem;
		}
	}

	.ant-menu-submenu-title {
		padding: 0px 7px !important;
		background-color: red;
	}
}
