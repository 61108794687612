.payroll-components {
	background-color: #fff;
	padding: 20px 20px 20px 8px;
	border-radius: 10px;
	.ant-tabs-tab-active {
		background-color: #584495 !important;
		color: white !important;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.ant-tabs-tab-btn {
		padding: 5px 10px;
		border: 1px solid gray;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom: none;
		color: gray;
		font-weight: 400 !important;
		padding: 5px 20px !important;
	}
	.ant-tabs-nav-list {
		gap: 10px !important;
	}
	.ant-tabs-tab {
		margin: auto !important;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: white !important;
		background-color: #584495 !important;
		padding: 5px 20px;
		font-size: 18px !important;
	}
	.ant-tabs-ink-bar {
		background-color: none !important;
	}
	.ant-tabs-nav::before {
		border-color: #584495 !important;
		border-width: 2px;
	}
	.ant-tabs-nav {
		margin-bottom: 15px !important;
	}
	.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
		font-size: 18px !important;
		padding: 5px 20px !important;
	}
}

// .ant-tooltip {
// 	color: #fff;
// 	background-color: #fff;
// 	.ant-tooltip-arrow {
// 		color: #fff;
// 	}
// }
