.run-payroll {
	padding: 20px;
	// padding-top:80px;
	padding-bottom: 32px;
	padding-inline: 116px;
	background-color: #f3f5f7;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100vh;
	// justify-content: center;
	&__header {
		display: flex;
		justify-content: space-between !important;
		align-items: center;
		margin-bottom: 20px;
		&__title {
			font-size: 2.4rem;
			font-weight: 600;
		}
		&__actions {
			cursor: pointer;
			display: flex;
			align-items: center;
			&__button {
				margin-right: 10px;
			}
		}
	}
	&__table {
		display: flex;
		border-radius: 10px;
		background-color: white;
		padding: 20px;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
	}
}

.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.modal-body {
	padding: 1rem;
}

.modal-buttons {
	margin-top: 20px;
}
