.preview-container {
	width: 100vw !important;
	height: 100vh !important;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;

	.ant-modal {
		&-header {
			background-color: #fff;
			padding-bottom: 1rem;
		}

		&-content {
			height: 100vh !important;
			width: 100vw !important;
			overflow-y: hidden !important;
			border-radius: 0 !important;
			background-color: #fff;
			padding: 2rem 3rem !important;
		}
	}

	// .ant-table-thead tr th {
	// 	background-color: #eff0ef !important;
	// 	border-top: 1px solid #c2c2c2;
	// 	border-bottom: 1px solid #c2c2c2;
	// 	border-radius: 0px !important;
	// }

	.ant-tabs-tab-active {
		background-color: #584495;
		color: white !important;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.ant-tabs-tab-btn {
		padding: 5px 20px !important;
		border: 1px solid #d9d9d9 !important; /* Add a black border */
		border-top-left-radius: 10px; /* Adjust the radius as needed */
		border-top-right-radius: 10px;
		border-bottom: none !important;
		color: gray;
		font-weight: 400 !important;
	}
	.ant-tabs-nav-list {
		gap: 10px !important;
	}

	.ant-tabs-tab {
		margin: auto !important;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: white !important;
		padding: 5px 20px;
		font-size: 18px !important;
	}
	.ant-tabs-ink-bar {
		background-color: none !important;
	}
	.ant-tabs-nav::before {
		border-color: #584495 !important; /* Replace with your desired color */
		border-width: 2px;
	}
	.ant-tabs-nav {
		margin-bottom: 15px !important;
	}
	.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
		font-size: 18px !important;
		padding: 5px 20px !important;
	}
}
