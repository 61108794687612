.payPeriod {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		padding: 1.5rem;
		> p {
			font-size: 22px;
			font-weight: 500;
			line-height: 25px;
			letter-spacing: -0.01em;
			text-align: left;
		}

		&-item {
			width: 30rem;
		}
	}
}
