.modal-header {
	justify-content: space-between;
	display: flex;

	&-filter {
		display: flex;
		gap: 3rem;
	}
}

.modal-body {
	padding: 1rem;

	&_plan {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 2rem 0rem;
	}
}

.total {
	font-weight: bold;
}
