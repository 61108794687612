// .netPay-dynamic-table {
// 	background-color: #fff !important;
// 	border-radius: 10px;
// 	height: 80vh !important;
// 	&__pagination {
// 		justify-content: center !important;
// 		.ant-pagination-item {
// 			border-radius: 50%;
// 		}
// 		.ant-pagination-item-active {
// 			border-color: #000;
// 			background-color: #000;
// 			&:hover {
// 				border: none !important;
// 				border-width: 0px !important;
// 			}
// 			a {
// 				color: #fff !important;
// 			}
// 		}
// 	}
// 	.table-edit-icon,
// 	.table-delete-icon {
// 		cursor: pointer;
// 	}

// 	&__no-action {
// 		cursor: not-allowed;
// 		display: flex;
// 		align-items: center !important;
// 		justify-content: center !important;
// 		opacity: 40%;
// 	}

// 	.ant-table-wrapper .ant-table-pagination.ant-pagination {
// 		margin: 5px 20px;
// 	}
// }

// /* For All the tables */
// .ant-table-container {
// 	min-height: calc(-75px + 50vh) !important;
// }

.netPay-total-row {
	margin-top: 0px;
	margin-right: 50px;
	display: flex;
	justify-content: flex-end;
}

.align-net-pay {
	display: inline-block;
	text-align: right;
	white-space: nowrap;
}

.cost-to-company-label {
	font-size: 14px;
	/* Font size for "Cost to Company" label */
	color: #000;
	/* Color for the label */
	display: block;
	/* Makes sure the label is on a separate line */
}

.total {
	color: #584495;
	/* Purple color for the value */

	display: block;
	/* Makes sure the value appears below the label */
	margin-top: 4px;
	/* Adds some spacing between the label and the value */
}
