.col {
	margin-bottom: 2rem;
}

.employment {
	overflow-y: auto;
	height: calc(100vh - 220px);
	&--employeeDetails {
		margin-top: 10px !important;
		width: 99%;
		margin-left: 5px;
	}
}
