.sidebar {
	min-width: 220px !important; 

	.sidebar__wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
	&__support {
		padding: 0 0 3.8rem 1.8rem;
		& > button {
			font-size: 12px !important;
			border: 2px solid #286fd1 !important;
			color: #286fd1 !important;
		}
	}
}
