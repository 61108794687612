.statutory-component {
	padding-top: 24px;

}

.modal-header {
	justify-content: space-between;
	display: flex;
   
	&-filter {
		display: flex;
		gap: 3rem;
	}

		.title {
			color:#584495;
			/* Set the color to purple */
			font-weight: bold;
			/* Make the text bold */
		}
	}


.close-button {
    justify-content: right;
}
