.scrollable-content {
	height: calc(100vh - 300px);
	overflow-y: auto;
	padding-right: 16px;
}

.card {
	background: #fff;
	border-radius: 12px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	padding: 0px;
	margin-bottom: 10px;

	.ant-card-head-title {
		font-size: 18px;
		font-weight: bold;
		color: #584495;
	}

	p {
		margin: 8px 0;
		font-size: 16px;
		line-height: 1.5;

		b {
			font-weight: bold;
			margin-right: 8px;
		}
	}
}
