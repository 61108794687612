.ant-input-number-outlined {
	width: 100%;
}

.input-column {
	padding: 0px;
}

.ant-picker {
	padding: 8px;
}

.ant-form-item {
	padding: 0;
}
.employee-share-input {
	display: flex;
	margin: 0;
	flex-direction: row;
	align-items: center;
	padding: 0;
	justify-content: center;
}
