.side-drawer-form {
	max-width: 100% !important;
	&__inputs {
		grid-template-columns: repeat(3, 1fr);
		padding-left: 3.636rem;
		padding-right: 3.636rem;
		padding-top: 10px !important;
	}
	&__notes {
		color: #7a828a;
		padding-top: 0 !important;
	}
	&__napsa {
		font-weight: 600 !important;
		&--label {
			display: flex;
			align-items: center;
			font-size: 15px;
			//	font-weight: 500 !important;
			color: #27272e;
		}
	}
	&__buttons {
		margin-top: 20px;
		padding-left: 3.336rem;
		padding-right: 3.336rem;

		&--btn {
			border: none;
			font-size: 1.8rem;
			padding: 1.8rem 4rem;
			border-radius: 5000px;
			cursor: pointer;
			font-weight: 600;
		}
		&--save {
			display: flex;
			padding: 1.3rem 2.8rem;
			max-height: 41.8px;
			align-items: center;
			justify-content: center;
			background: #fff;
			border: 1px solid #000;
			box-shadow: none;
			font-size: 1.6rem;
			color: #000;
			font-weight: 600;
			border-radius: 6px !important;
			cursor: pointer;
			width: 13rem;

			&:hover {
				color: #fff !important;
				background: #286fd1 !important;
				border: 1px solid #286fd1;
			}
		}

		&--cancel {
			display: flex;
			padding: 2rem 2.8rem;
			align-items: center;
			justify-content: center;
			background: #fff;
			border: 1px solid #000;
			box-shadow: none;
			font-size: 1.6rem;
			max-height: 41.8px;
			color: #000;
			font-weight: 600;
			border-radius: 6px !important;
			cursor: pointer;
			width: 13rem;
			&:hover {
				background: #fff !important;
				color: #27272e !important;
				border-color: #000 !important;
			}
		}
	}
	&--textarea {
		width: 150%;
		border-color: rgb(217, 217, 217);
		border-radius: 8px;
		padding: 11px 7px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.88);
		min-height: 9.6rem !important;
		font-weight: 400 !important;
		outline: none;
		margin-top: 0px;
		font-style: normal;

		&::placeholder {
			color: #c7c7c7;
		}
	}
}

.helper-text {
	font-size: 12px;
	font-weight: 400;
	line-height: 26px;
	text-align: left;
	color: #666666;
}

.field-container {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	.field-container-label {
		margin-bottom: 10px;
	}
}
.pay-radio {
	font-size: 15px !important;
	// margin-bottom: 10px;
}
.form-container-head-warning {
	font-size: 14px;
	color: red;
	text-align: end;
	padding-right: 40px;
}

.diff-days-label {
	font-size: 12px;
	// font-weight: 500;
	color: grey;
	margin-block: 4px;
}
