
.statusContainer {
	display: flex;
	.statusTag {
		display: flex;
		align-items: center;
		padding: 10px, 12px, 10px, 12px;
		border-radius: 8px;
	}
}
.status-active {
	background-color: #e5f8e8;
	color: #28a745;
	font-size: 14px;
}
.status-inactive {
	background-color: #fdeeef;
	color: #df081a;
	font-size: 14px;
}
.statusIcon {
	display: flex;
	align-items: center;
	gap: 6px;
}
