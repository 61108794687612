.employee {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
			background-color: #fff;
		padding: 1.5rem;
		&-item {
			width: 30rem;
		}
	}
}
