.pay-groups {
	padding: 20px;
	background-color: #f3f5f7;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 120vh !important;
	// justify-content: center;
	&__header {
		display: flex;
		justify-content: space-between !important;
		align-items: center;
		margin-bottom: 20px;
		&__title {
			font-size: 2.4rem;
			font-weight: 600;
		}
		&__actions {
			cursor: pointer;
			display: flex;
			align-items: center;
			&__button {
				margin-right: 10px;
			}
		}
	}
	&__table {
		display: flex;
		border-radius: 10px;
		background-color: white;
		// padding: 20px;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
	}
}

.employee-list {
	display: flex;
	flex-direction: column;
	padding-left: 25px;
	margin-bottom: 20px;
	width: 1000px;
}

.employee-header {
	font-size: 17px;
	font-weight: 500;
	margin: 0px 10px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: #584495;
	border-bottom: 1px solid black;
	padding-bottom: 5px !important;
}

.employee-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 10px;
	// border-bottom: 1px solid black;
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

.employee-add-button {
	height: 25px;
	color: #584495;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #584495;
}

.employee-listing-container {
	display: flex;
	flex-direction: column;
	justify-content: start;
	max-height: 150px;
	overflow-y: scroll;
}

.employee-listing-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}
