.modal-header {
	justify-content: space-between;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	> h4 {
		text-align: center;
		margin: 0;
	}
}

.close-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal-body {
	padding: 1rem;
}
.title {
	font-size: 16px;
	margin-bottom: 10px;
}
.currency-selector {
	margin-bottom: 1.5rem;
}

.currency-checkbox {
	margin-bottom: 10px;
}
