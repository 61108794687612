.employeeApprovalContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  &--checkbox{
    min-height: calc(100vh - 280px) !important;
  }
}

.buttonsContainer {
  // margin-top: 100px; 
  margin-top: auto;
  align-self: flex-start;
}
