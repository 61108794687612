.header-employee-container {
	background-color: #fff;
	padding: 20px 20px 20px 8px;
	border-radius: 10px;
	.ant-tabs-tab-active {
		background-color: #584495 !important;
		color: white !important;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.ant-tabs-tab-btn {
		padding: 5px 20px !important;
		border: 1px solid #d9d9d9 !important; /* Add a black border */
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom: none !important;
		color: gray;
		font-weight: 400 !important;
	}
	.ant-tabs-nav-list {
		gap: 10px !important;
	}
	.ant-tabs-tab {
		margin: auto !important;
		margin-bottom: 0px !important;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: white !important;
		background-color: #584495 !important;
		padding: 5px 20px;
		font-size: 18px !important;
	}
	.ant-tabs-ink-bar {
		background-color: none !important;
	}
	.ant-tabs-nav::before {
		border-color: #584495 !important;
		border-width: 2px;
	}
	.ant-tabs-nav {
		margin-bottom: 15px !important;
	}
	.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
		font-size: 18px !important;
		padding: 5px 20px !important;
	}
}

.uploadCsvModal {
	.ant-modal-wrap {
		overflow-y: hidden !important;
	}

	.ant-table-thead {
		background-color: red !important;
	}
}

.validation-results {
	margin: 10px 0px !important;
	.ant-table-container {
		min-height: auto !important;
		margin-bottom: 20px !important;
	}
	.ant-table-footer {
		background-color: white !important;
		border: none !important;
		height: 15px !important;
		line-height: 20px !important;
		padding: 0 !important;
		margin: 0 !important;
	}
}
