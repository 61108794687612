.menu-item {
	background-color: #fff;
	border: none !important;

	.ant-menu-item {
		border-radius: 0px;
		padding: 10px !important;
		margin: 0 !important;
		width: 100%;
		height: auto !important;
		gap: 8px;
		.ant-menu-title-content {
			font-size: 1.8rem !important;
			color: #000;
			padding-left: 10px !important;
			font-weight: 600;
		}
		svg {
			padding-top: 2px !important;
			overflow: visible;
		}
	}

	.ant-menu-submenu {
		border-radius: 0px;
		padding: 10px 0px !important;
		margin-left: -10px !important;
		// padding-right: 17px !important;
		width: calc(100% + 10px) !important;
		height: auto !important;
		display: flex;
		align-items: center;
		gap: 8px;
		.ant-menu-title-content {
			font-size: 1.8rem !important;
			color: #000;
			font-weight: 600;
		}
		// .ant-menu-submenu-popup {
		// 	font-weight: 300;
		// 	font-size: 0.5rem;
		// }
	}
	.ant-menu-submenu-selected {
		background-color: #45326c !important;
		width: 100%;
		.ant-menu-title-content {
			color: white !important;
		}
		svg {
			color: #fff !important;
		}
		.ant-menu-submenu-arrow {
			color: #ffff;
		}
		&:hover {
			background-color: #45326c !important;
		}
	}

	.ant-menu-item-selected {
		background-color: #45326c !important;
		width: 100%;
		.ant-menu-title-content {
			color: white !important;
		}
		svg {
			color: #fff !important;
		}
	}

	.ant-menu-item-active {
		color: white !important;
	}

	.ant-menu-submenu-title {
		display: flex !important;
		align-items: center !important;
		gap: 10px !important;
	}
}

.ant-layout-sider-children {
	height: 100%;
}

.ant-menu-item {
	gap: 18px !important;
	color: #fff !important;
}

.ant-menu-item-only-child {
	color: #000 !important;
}
.ant-menu-submenu-title {
	color: black !important;
}
// .ant-menu-submenu {
// 	font-size: 1.8rem !important;
// 	color: #000;
// 	font-weight: 600;
// 	margin-left: -10px !important;
// 	padding-top: 5px !important;
// 	padding-bottom: 5px !important;
// }
// .ant-menu-submenu-active {
// 	font-size: 1.8rem !important;
// 	color: #000;
// 	font-weight: 600;
// 	margin-left: -10px !important;
// 	padding-top: 5px !important;
// 	padding-bottom: 5px !important;
// 	background-color: #f3f8ff !important;
// }

// .ant-menu-item-only-child:hover {
// 	background-color: #f3f8ff !important;
// }

.ant-menu-submenu-arrow {
	color: #433068;
}
:where(.css-dev-only-do-not-override-5d3dga).ant-menu-light:not(
		.ant-menu-horizontal
	)
	.ant-menu-submenu-title:hover,
:where(.css-dev-only-do-not-override-5d3dga).ant-menu-light
	> .ant-menu:not(.ant-menu-horizontal)
	.ant-menu-submenu-title:hover {
	background-color: rgba(0, 0, 0, 0);
}

.ant-menu-submenu:hover {
	background: #f3f8ff !important;
}
.ant-menu-submenu-popup:hover {
	background: none !important;
}

.ant-switch-checked {
	background: #584495 !important;
}
