.year-pay-period-filter {
	gap: 2rem;
	.input-field {
		.ant-picker {
			min-height: 43px;
		}
		.ant-select {
			min-height: 43px !important;
			width: 200px !important;
		}
	}
}
