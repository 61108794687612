.container {
	font-size: 14px;
}

.infoSection {
	margin-top: 24px;
	margin-bottom: 24px;
}
span {
	font-weight: normal !important;
}
.infoItem {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
	:first-child {
		font-weight: bold;
		margin: 0;
		font-size: 14px;
	}
}

.tableWrapper {
	max-height: 200px;
	overflow-y: auto;
	margin-top: 12px;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.deductionsTable {
	width: 100%;
	border-collapse: collapse;

	th,
	td {
		padding: 8px 12px;
		text-align: left;
		border-bottom: 1px solid #e8e8e8;
	}

	th {
		background-color: #f0f2f5;
		font-weight: bold;
	}

	tr:last-child td {
		border-bottom: none;
	}
}

.footerButtons {
	margin-top: 20px;
	text-align: right;
}
